/* You can add global styles to this file, and also import other style files */
@use "~@angular/material" as mat;
@import "~@circlon/angular-tree-component/css/angular-tree-component.css";
@import "~@angular/material/theming";
@include mat.core();

$mat-primary: mat.define-palette(mat.$indigo-palette);
$mat-accent: mat.define-palette(mat.$pink-palette);
$mat-warn: mat.define-palette(mat.$red-palette);

:root {
  --mat-primary: #{mat.get-color-from-palette($mat-primary)};
  --mat-accent: #{mat.get-color-from-palette($mat-accent)};
  --mat-warn: #{mat.get-color-from-palette($mat-warn)};
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.scrollable {
  overflow: auto;
}

.thin-frame {
  border: 1px solid lightgrey;
}

.thin-frame-warn {
  border: 1px solid
    lighten($color: mat.get-color-from-palette($mat-warn), $amount: 24);
}

.grey-bg {
  background-color: rgb(236, 236, 236);
}

.border-gray {
  border: 1px solid darkgray;
  border-radius: 4px;
}

.content {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 16px;
}

.thin-frame-r4 {
  border: 1px solid darkgray;
  border-radius: 4px;
}

.thin-frame-faded6 {
  border: 1px solid rgba(169, 169, 169, 0.6);
}

.thin-frame-faded4 {
  border: 1px solid rgba(169, 169, 169, 0.4);
}

.document-title-tooltip {
  font-size: 15px;
  max-width: unset !important;
}
